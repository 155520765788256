///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

#main {
  -webkit-flex: 1;    /* Safari 6.1+ */
  -ms-flex: 1;        /* IE 10 */
  flex: 1;            /* Standard for modern browsers */
  
  width: 100%;        /* Ensure full width */
}

